import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { titleCaseValue } from '@dte/otw/utils/core/src/strings/titleCaseValue';
import { upperCaseValue } from '@dte/otw/utils/core/src/strings/upperCaseValue';
import type { PostalAddressDetails } from '../../../../interfaces/api/PostalAddressDetails';
import type { LocationDetails } from '../../../../interfaces/api/PremiseStatusV2';
import { MessageIcon } from '../../../../interfaces/api/messageDetail/MessageIcon';
import type { PremiseData, PremiseDataDocument } from '../../../../interfaces/containers/premises/PremiseData';
import { getAddressLine1 } from './getAddressLine1';
import { getAddressLine2 } from './getAddressLine2';

function getGPS(masterData: PremiseData) {
	// GeoJSON coordinates
	const coordinates = masterData?.gps?.coordinates;
	const longitude = coordinates?.[0];
	const latitude = coordinates?.[1];

	if (!latitude || !longitude) {
		return undefined;
	}

	return {
		latitude: latitude,
		longitude: longitude,
	};
}

export function getPremiseAddress(masterData: PremiseDataDocument): PostalAddressDetails {
	const data = masterData?.data;
	const address = data?.address;
	if (!address) {
		return undefined;
	}

	const addressDetails: PostalAddressDetails = {
		line1: getAddressLine1(address),
		line2: getAddressLine2(address),
		city: titleCaseValue(address.city),
		state: upperCaseValue(address.state),
		zipcode: normalizeValue(address.zip),
	};

	return addressDetails;
}

export function getPremiseLocation(masterData: PremiseDataDocument): LocationDetails {
	const data = masterData?.data;
	const address = data?.address;
	if (!address) {
		return undefined;
	}

	const locationDetails: LocationDetails = {
		premiseId: masterData.premiseId,
		icon: MessageIcon.MapPin,
		gps: getGPS(data),
		address: getPremiseAddress(masterData),
	};

	return locationDetails;
}
