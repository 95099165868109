import { filterEmpty } from './filterEmpty';

// TODO: handle objects or things that we can't stringify
// TODO: Make omitEmptyValues the default because that's usually what we want
export function uniqueValues<T>(values: T[], omitEmptyValues?: boolean): T[] {
	if (!values?.length) {
		return [];
	}

	const unique: Record<string, T> = {};
	for (const value of values) {
		const jsonValue = JSON.stringify(value);
		unique[jsonValue] = value;
	}

	let uniqueValues = Object.values(unique);
	if (omitEmptyValues) {
		uniqueValues = filterEmpty(uniqueValues);
	}

	return uniqueValues;
}
