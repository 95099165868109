import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import isLength from 'validator/es/lib/isLength';
import { ValidationError } from '../errors/ValidationError';

export function validateString(
	name: string,
	value: string,
	minLength?: number,
	// Put a limit on input strings
	maxLength = 100,
	required: boolean = true,
): string {
	const normalized = normalizeValue(value);

	if (!normalized) {
		if (required) {
			throw new ValidationError(`Invalid value: ${name} is required`);
		}
		return normalized;
	}

	if (!isLength(normalized, { min: minLength, max: maxLength })) {
		throw new ValidationError(
			`Invalid value: ${name} must be between ${minLength} and ${maxLength} characters long`,
		);
	}

	return normalized;
}

export function isValidStringLength(
	value: string,
	minLength?: number,
	// Put a default limit on input strings
	maxLength = 100,
	required: boolean = false,
): boolean {
	try {
		validateString('value', value, minLength, maxLength, required);
		return true;
	} catch {
		return false;
	}
}
