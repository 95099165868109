'use client';

import { filterEmpty } from '@dte/otw/utils/core/src/lists/filterEmpty';
import { getArray } from '@dte/otw/utils/core/src/lists/mergeArrays';
import { uniqueValues } from '@dte/otw/utils/core/src/lists/uniqueValues';
import { isEmpty } from '@dte/otw/utils/core/src/objects/isEmpty';
import { normalizeValues } from '@dte/otw/utils/core/src/strings/normalizeValues';

export function joinClasses(...classes: string[]): string {
	let classNames = getArray(classes);

	// Seprate the classes by spaces
	classNames = classNames.join(' ').split(' ');

	// Make sure we have only unique values
	classNames = normalizeValues(classNames);
	classNames = uniqueValues(classNames);
	classNames = filterEmpty(classNames);

	if (isEmpty(classNames)) {
		return undefined;
	}

	return classNames.join(' ');
}

export function addClassName(existingClassName: string, className: string): string {
	return joinClasses(existingClassName, className);
}
