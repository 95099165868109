import { type Options, titleCase } from 'title-case';
import { lowerCaseValue } from './lowerCaseValue';
import { normalizeValue } from './normalizeValue';
import { upperCaseValue } from './upperCaseValue';

const options: Options = {
	locale: 'en-US',
};

const specialPrefixes = ['Mc', "O'"];

function titleCaseWord(value: string) {
	if (!value?.length) {
		return '';
	}

	let formatted = value;

	// If the value is all uppercase, convert it to lowercase before changing to title case
	if (formatted === upperCaseValue(formatted)) {
		formatted = lowerCaseValue(formatted);
	}

	formatted = titleCase(formatted, options);

	// If the value is a special prefix, title case the part that follows the prefix
	for (const prefix of specialPrefixes) {
		const prefixLength = prefix.length;
		if (formatted.startsWith(prefix) && formatted.length > prefixLength) {
			const prefixValue = formatted.slice(0, prefixLength);
			const suffixValue = formatted.slice(prefixLength);
			formatted = `${prefixValue}${titleCaseWord(suffixValue)}`;
			break;
		}
	}

	return formatted;
}

export function titleCaseValue(value: string) {
	const formatted = normalizeValue(value);
	if (!formatted) {
		return formatted;
	}

	let parts = formatted.split(' ');
	parts = parts.map(titleCaseWord);

	return parts.join(' ');
}
