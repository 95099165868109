export enum MessageIcon {
	MapPin = 'MapPin',
	PowerOff = 'PowerOff',
	PowerOn = 'PowerOn',
	PowerUnknown = 'PowerUnknown',
	Exclamation = 'Exclamation',
	Clock = 'Clock',
	Checkmark = 'Checkmark',
	MagnifierElectric = 'MagnifierElectric',
}
