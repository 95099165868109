import { safeJoin } from '@dte/otw/utils/core/src/strings/safeJoin';
import { titleCaseValue } from '@dte/otw/utils/core/src/strings/titleCaseValue';
import type { PremiseDataAddress } from '../../../../interfaces/containers/premises/PremiseData';

export function getAddressLine1(address: PremiseDataAddress): string {
	const parts = [
		address.houseNumber,
		address.preDirection,
		address.streetName,
		address.streetSuffix,
		address.postDirection,
	];

	let joined = safeJoin(parts);
	joined = titleCaseValue(joined);

	return joined;
}
