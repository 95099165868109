import { safeJoin } from '@dte/otw/utils/core/src/strings/safeJoin';
import { titleCaseValue } from '@dte/otw/utils/core/src/strings/titleCaseValue';
import type { PremiseDataAddress } from '../../../../interfaces/containers/premises/PremiseData';

export function getAddressLine2(address: PremiseDataAddress): string {
	const parts = [address.secondaryCode, address.secondaryValue];

	let joined = safeJoin(parts);
	joined = titleCaseValue(joined);

	return joined;
}
